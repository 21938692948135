import React, { useContext, useEffect, useRef, useState } from 'react';
import { Fade } from 'react-reveal';
import { UserContext } from '../../App';


function Home() {
    const [current, setCurrent] = useState('user');
    const userRef = useRef();
    const businessRef = useRef();
    const contentRef = useRef();
    const [visible, setVisible] = useState(false);
    const [lang, setLang] = useState();
    let flag = useContext(UserContext);

    useEffect(() => {
        setLang(flag);
        console.log(flag);
    }, [flag])


    function userClick() {
        setCurrent('user');
        document.getElementById("storeBtn").style.visibility = "hidden";

        // console.log(contentRef.current);
        // contentRef.current = "Whether you own a store or are looking for one, Linko is the only app you need.<br/><br/>Find any product or business with a few taps on one app. Looking for a couch, a mobile charger and clothes all while you have your morning coffee? Do it all via Linko. Add stores to your favorites list, be the first to know about their offers and discounts, and make shopping what it should be - FUN.";
        // userRef.current.style.backgroundColor="#EA3557";
        // businessRef.current.style.backgroundColor = "#FFFFFF";
    }

    function businessClick() {
        setCurrent('business');
        document.getElementById("storeBtn").style.visibility = "visible";
        // console.log(contentRef.current);
        // contentRef.current = "What's in it for the business owners? Well, your business can only grow when your customer is in the know. Linko has one mission only - to help people find you. List your store details and products on the app so everyone can find you instantly. Prompt visitors to add your store to their Linko list. Run offers and promotions to keep your store on top.<br/><br/>Many businesses, one platform - Linko is here to make finding stores as easy as 1,2,3.";
        // businessRef.style.backgroundColor="#EA3557";
        // userRef.current.style.backgroundColor = "#FFFFFF";
    }

    useEffect(() => {
        document.getElementById('content').innerHTML = lang == true ? (current == 'user' ? 
        "Whether you own a store or are looking for one, Linko is the only app you need.<br/><br/>Find any product or business with a few taps on one app. Looking for a couch, a mobile charger and clothes all while you have your morning coffee? Do it all via Linko. Add stores to your favorites list, be the first to know about their offers and discounts, and make shopping what it should be - FUN." 
        : "What's in it for the business owners? Well, your business can only grow when your customer is in the know. Linko has one mission only - to help people find you. List your store details and products on the app so everyone can find you instantly. Prompt visitors to add your store to their Linko list. Run offers and promotions to keep your store on top.<br/><br/>Many businesses, one platform - Linko is here to make finding stores as easy as 1,2,3.") :
        (current == 'user' ? 
        "ما الفائدة من ذلك لأصحاب الأعمال؟ حسنًا ، يمكن أن ينمو عملك فقط عندما يكون عميلك على دراية. لدى Linko مهمة واحدة فقط - لمساعدة الأشخاص في العثور عليك. أدرج تفاصيل متجرك ومنتجاتك في التطبيق حتى يتمكن الجميع من العثور عليك على الفور. اطلب من الزوار إضافة متجرك إلى قائمة Linko الخاصة بهم. قم بتشغيل العروض والعروض الترويجية للحفاظ على متجرك في المقدمة.<br/><br/> العديد من الشركات ، منصة واحدة - Linko هنا لجعل العثور على المتاجر أمرًا سهلاً مثل 1،2،3."
        :"this is same text....  the business owners? Well, your business can only grow when your customer is in the know. Linko has on");
    }, [current, lang]);

    return (
        <div id="Home" className='flex flex-row justify-between items-center mt-[50px] mr-[100px] mb-[205px] ml-[106px] box-border h-[600px] max-[1000px]:ml-[80px] max-[1000px]:mr-[80px] max-[1000px]:mt-[70px] max-[800px]:flex-col max-[800px]:h-full max-[800px]:items-center max-[800px]:ml-[30px] max-[800px]:mb-[100px] max-[800px]:mr-[30px] max-[410px]:ml-0 max-[410px]:mr-0 max-[550px]:mt-9 max-[550px]:mb-[40px]'>
            <div className="flex flex-col justify-between max-[800px]:items-center max-w-[60%] max-[700px]:max-w-[80%] max-[550px]:max-w-[90%]">                
                <Fade left>
                    <p className="font-['Sen sans-serif'] font-[700] text-[60px] max-[1000px]:text-[48px] max-[480px]:text-[40px] max-[480px]:text-left max-[480px]:leading-[48px] leading-[72px] tracking-[-0.02em] pb-[30px] max-[800px]:text-center">{lang ? "Add your business website in Minutes" : "أضف موقعك الإلكتروني في دقائق!"}</p>
                </Fade>
                <Fade left>
                <p ref={contentRef} id="content" className="font-['Helvetica Neue'] font-normal text-[21px] max-[1000px]:text-[18px] leading-[25px]  text-justify text-[#626167] pb-[30px]">                
                </p>
                <div className='flex flex-row justify-start pb-[30px]'>
                    <button ref={userRef} onClick={userClick} className={`w-[145px] h-[43px] rounded-[10px] font-['Sen sans-serif'] font-[700] text-[20px] tracking-[-0.02em]  mr-[18px] ${current == 'user' ? 'bg-[#EA3557] text-white border-none' : 'bg-white text-black border-solid border-[1px] border-[#EA3557]'}`}>{lang ? "Hey users!" : "هلا عميلنا!"}</button>
                    <button ref={businessRef} onClick={businessClick} className={`w-[145px] h-[43px] rounded-[10px] font-['Sen sans-serif'] font-[700] text-[20px] leading-[24px] tracking-[-0.02em] ${current  == 'user' ? "bg-white border-solid border-[1px] border-[#EA3557] text-black" : "bg-[#EA3557] text-white border-none"}`}>{lang ? "Hey Business!" : "هلا بالشريك!"}</button>
                </div>
                <button id="storeBtn" className="invisible w-[310px] h-[43px] bg-black text-white text-[20px] rounded-[10px] max-[800px]:mb-[30px]">{lang ? "You don't have store yet?!" : "ما عندك متجر الكتروني؟ "}</button>
                </Fade>
            </div>
            <Fade right>
                <div className='relative'>
                    <div className="p-[13px] z-20 absolute top-12 left-0 -translate-x-24 w-[192px] h-[96px] max-[550px]:w-[160px] max-[550px]-[80px] max-[550px]:-translate-x-20 rounded-[10px] shadow-[0_42px_84px_rgba(0,0,0,0.158)] bg-white">
                        <p className="font-['Malik Trial'] text-[20px] leading-[29px] text-black     text-center">It's totally</p>
                        <p className="font-['Malik Trial'] font-[800] text-[35px] leading-[51px] text-[#EA3557] text-center">Free!</p>
                    </div>
                    <div className="flex flex-row justify-center items-center -translate-x-[31px] z-20 absolute bottom-24 w-[62px] h-[62px] rounded-[50%] bg-[#F8F8FA] shadow-[0_2px_5px_rgba(0,0,0,0.15)]">
                        <img width={30} height={30} src='./assets/percent.png' alt="percent"/>
                    </div>
                    <img className="z-20 absolute bottom-24 right-2 translate-x-28 max-[550px]:w-[130px] max-[550px]-[66px] max-[550px]:translate-x-20" src='./assets/Coupon.png' alt="Coupon"/>
                    <img className='w-[259px] h-[518px] max-[1000px]:w-[230px] max-[1000px]:h-[460px] max-[400px]:w-[200px] max-[400px]:h-[400px]' src='./assets/home-phone.png' alt='Home Phone' />
                </div>
            </Fade>
            <div className={`fixed flex flex-col top-0 bottom-0 p-2 border-[1px] border-solid border-[#C9E5FF] w-[250px] delay-500 overflow-hidden ${visible ? "left-0":"left-[-300px]"}`}>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[30px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Home'>Home</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[30px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#How'>How?</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[30px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Feature'>Features</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[30px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Faq'>Faq</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[30px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#'>Contact us</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] text-[#222529] hover:text-[#868E96]" href='#'>| Arabic</a>
            </div>

        </div>
    )
}

export default Home;