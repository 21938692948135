import React, {useState, useRef} from 'react';
import {notification} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import emailjs from '@emailjs/browser';

// css
import './style.css';


const DeleteStore = () => {
    const [selectReason, setSelectReason] = useState('');
    const [loading, setLoading] = useState(false);
    const formRef = useRef();

    const sendMessage = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm('service_gncd6yp', 'template_hdkv3kb', formRef.current, '6ECRfjQzZ9D0LClh7').then(res => {
            setSelectReason("");
            setLoading(false);
            notification['success']({message: 'Your request has been sent'});
            formRef.current[0].value = "";
            formRef.current[1].value = "";
        }).catch(err => {
            notification['success']({message: 'Request was not sent. Please check your internet settings and try again'});
            setLoading(false);
        })
    }

    return(
        <div className={'delete-store-block'}>
            <div className={'delete-store-header'}>
                <h1> Delete Store </h1>
            </div>
            <div className={'header-img'}>
                <img src="/assets/icon.png" alt="icon"/>
            </div>
            <div className={'store-form'}>
                <form ref={formRef} onSubmit={sendMessage}>
                    <div className={'form-item'}>
                        <label>Store Name</label>
                        <input required name={'store_name'} type="text" placeholder={"please enter your store name"}/>
                    </div>
                    <div className={'form-item'}>
                        <label>Owner No.</label>
                        <input required name={'phone_number'} type="number" placeholder={"please enter your mobile number"}/>
                    </div>
                    <div className={'select-reason'}>
                        <div className={selectReason === 'Others' && 'selected-reason'} onClick={() => setSelectReason("Others")}>
                            Others
                        </div>
                        <div className={selectReason === 'We closed our store' && 'selected-reason'} onClick={() => setSelectReason("We closed our store")}>
                            We closed our store
                        </div>
                        <div className={selectReason === 'No need to be in Linko anymore' && 'selected-reason'} onClick={() => setSelectReason("No need to be in Linko anymore")}>
                            No need to be in Linko anymore
                        </div>
                    </div>
                    <div className={`header-img ${!selectReason && 'disabled-btn'}`}>
                        <button> {loading && <LoadingOutlined/>} Save</button>
                    </div>
                    <input type="text" name={'message'} style={{display: 'none'}} value={selectReason}/>
                </form>
            </div>
        </div>
    )
}


export default DeleteStore;