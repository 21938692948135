import React, {useEffect, useState} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Landing from './components/Landing';
import DeleteStore from "./components/Delete";
import About from './components/About';


export const UserContext = React.createContext();

function App() {
    const [flag, setFlag] = useState(true);
    const changeFlag = () => setFlag(!flag);


    return (
        <UserContext.Provider value={flag}>
            <div className="pt-0 pr-[88px] pb-0 pl-[89px] scroll-smooth max-[1200px]:px-[10px]">
                <Routes>
                    <Route path='/' element={<Landing flag={flag} change={() => changeFlag()}/>}/>
                    <Route path='/legal-terms' element={<Terms/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/about/deleteme' element={<DeleteStore/>}/>
                    <Route path='*' element={<Navigate to={'/'}/>}/>
                </Routes>
                <Footer/>
            </div>
        </UserContext.Provider>
    );
}

export default App;
