import React, {useState, useEffect, useContext} from 'react';
import SideMenu from '../SideMenu';
import {useNavigate} from 'react-router-dom';
import { UserContext } from '../../App';

function Header(props) {
    const [visible, setVisible] = useState(false);
    const [language, setLanguage] = useState(true);
    const [lang, setLang] = useState();
    const flag = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    function openMenu() {
        setVisible(true);
    }

    function changeLang() {
        document.getElementById('lang').innerHTML = language ? "| English" : "| Arabic";
        props.change();
        setLanguage(!language); 
    }

    return (
        <div>
        <div className='flex flex-row justify-between items-center pt-[41px] pb-[41px] pl-[106px] pr-[181px] max-[1000px]:pl-[80px] max-[1000px]:pr-[80px] max-[800px]:pl-[50px] max-[800px]:pr-[30px] max-[550px]:px-3 max-[550px]:pb-0'>
            <img className='w-[139px] h-[51px]' src='./assets/logo.svg' alt='logo'/>
            <div className='flex flex-row justify-between items-center max-[650px]:hidden'>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pr-[41px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Home'>{lang? "Home" : "الرئيسية" }</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pr-[41px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#How'>{lang? "How?" : "كيف؟"}</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pr-[41px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Feature'>{lang? "Features": "المميزات"}</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pr-[41px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Faq'>{lang ? "Faq":"أسئلة"}</a>
                <a className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pr-[41px] max-[800px]:pr-[20px] text-[#222529] hover:text-[#868E96]" href='#Footer'>{lang? "Contact us":"تواصل معنا"}</a>
                <a id='lang' onClick={changeLang} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] text-[#222529] hover:text-[#868E96]" href='#'>| Arabic</a>
            </div>
            <button onClick={openMenu} className="min-[650px]:hidden"><img src="./assets/Menu.png" alt="menu" width={24} height={16}/></button>
        </div>
        <SideMenu visible={visible} closeMenu={() => setVisible(false)} changeLang={() => props.change()} />
        </div>
    );
}

export default Header;