import React from 'react';

function Terms() {
    
    return (
        <div className="p-[80px] max-[800px]:p-[30px]">
            <div className="flex flex-col w-full mb-[150px] max-[800px]:mb-[80px]">
                <p className="font-['Sen sans-serif'] font-[700] text-[60px] leading-[72px] max-[800px]:text-[48px] max-[800px]:leading-[60px] tracking-[-0.02em] text-[#1F1E2D] pb-[32px] max-[800px]:pb-[25px]">Terms and Conditions</p>
                <p className="font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D] pb-[70px] max-[800px]:pb-[48px]">END USER LICENSE AGREEMENT</p>
                <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] max-[800px]:text-[16px] max-[800px]:leading-[20px] text-justify text-[#626167]">Linko is licensed to You (End-User) by Konrad , located and registered at Elite tower, Floor 14, Office 2, Block 11, St 55, Qibla, Asimah 14000, Kuwait ("Licensor"), for use only under the terms of this License Agreement.
                By downloading the Licensed Application from Apple's software distribution platform ("App Store") and Google's software distribution platform ("Play Store"), and any update thereto (as permitted by this License Agreement), You indicate that You agree to be bound by all of the terms and conditions of this License Agreement, and that You accept this License Agreement. App Store and Play Store are referred to in this License Agreement as "Services."<br/>
                The parties of this License Agreement acknowledge that the Services are not a Party to this License Agreement and are not bound by any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance and support thereof. Konrad , not the Services, is solely responsible for the Licensed Application and the content thereof.<br/>
                This License Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest Apple Media Services Terms and Conditions and Google Play Terms of Service ("Usage Rules"). Konrad acknowledges that it had the opportunity to review the Usage Rules and this License Agreement is not conflicting with them.<br/>
                Linko when purchased or downloaded through the Services, is licensed to You for use only under the terms of this License Agreement. The Licensor reserves all rights not expressly granted to You. Linko is to be used on devices that operate with Apple's operating systems ("iOS" and "Mac OS") or Google's operating system ("Android").<br/>
                </p>
            </div>

            <div className='flex flex-col w-full'>
                <div className='flex flex-col mb-[50px]'>
                    <p className="pb-[15px] font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D]">1. The APPLICATION</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] text-justify text-[#626167]">Linko ("Licensed Application") is a piece of software created to be an online directory for different ecommerce businesses in Kuwait - Electronics, Fashion, Food, Home and Furniture etc. — and customized for iOS and Android mobile devices ("Devices"). It is used to To be an online directory for ecommerce marketplaces to feature their businesses.</p>
                </div>

                <div className='flex flex-col mb-[50px]'>
                    <p className="pb-[15px] font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D]">2. SCOPE OF LICENSE</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] text-justify text-[#626167]">2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Devices that You (End-User) own or control and as permitted by the Usage Rules, with the exception that such Licensed Application may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.<br/>
                        2.2 This license will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate license is provided for such update, in which case the terms of that new license will govern.<br/>
                        2.3 You may not share or make the Licensed Application available to third parties (unless to the degree allowed by the Usage Rules, and with Konrad 's prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application.
                        2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof (except with Konrad 's prior written consent).<br/>
                        2.5 You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Licensed Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time. If you sell your Devices to a third party, you must remove the Licensed Application from the Devices before doing so.<br/>
                        2.6 Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.
                        2.7 Licensor reserves the right to modify the terms and conditions of licensing.
                        2.8 Nothing in this license should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable third-party terms and conditions.<br/></p>
                </div>

                <div className='flex flex-col mb-[50px]'>
                    <p className="pb-[15px] font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D]">3. TECHNICAL REQUIREMENTS</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] text-justify text-[#626167]">3.1 Licensor attempts to keep the Licensed Application updated so that it complies with modified/new versions of the firmware and new hardware. You are not granted rights to claim such an update.<br/>
                    3.2 You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.<br/>
                    3.3 Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.<br/></p>
                </div>

                <div className='flex flex-col mb-[50px]'>
                    <p className="pb-[15px] font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D]">4. MAINTENANCE AND SUPPORT</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] text-justify text-[#626167]">4.1 The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You can reach the Licensor at the email address listed in the App Store or Play Store Overview for this Licensed Application.<br/>
                        4.2 Konrad and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support services with respect to the Licensed Application.</p>
                </div>
            </div>

        </div>
    )
}

export default Terms;