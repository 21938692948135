import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';

function SideMenu(props) {

    function closeMenu() {
        props.closeMenu(false);
    }

    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    function changeLang() {
        props.changeLang();
    }

    return (
        <div>
            <div className={`shadow-2xl fixed p-6 flex flex-col z-20 top-0 bottom-0 border-[1px] border-solid bg-gray-100 w-[250px] transition delay-[2000ms] duration-[2000ms] ease-[cubic-bezier(0.95,0.05,0.795,0.035)] overflow-hidden ${props.visible ? "left-0" : "left-[-300px]"}`}>
                <button onClick={closeMenu} className='ml-[90%]'><img src="./assets/close.png" alt="close" /></button>
                <a onClick={closeMenu} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pt-14 pb-[36px] text-[#222529] hover:text-[#868E96]" href='#Home'>{lang ? "Home" : "الرئيسية"}</a>
                <a onClick={closeMenu} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[36px] text-[#222529] hover:text-[#868E96]" href='#How'>{lang ? "How?" : "كيف؟"}</a>
                <a onClick={closeMenu} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[36px] text-[#222529] hover:text-[#868E96]" href='#Feature'>{lang ? "Features" : "المميزات"}</a>
                <a onClick={closeMenu} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[36px] text-[#222529] hover:text-[#868E96]" href='#Faq'>{lang ? "Faq" : "أسئلة"}</a>
                <a onClick={closeMenu} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] pb-[36px] text-[#222529] hover:text-[#868E96]" href='#Footer'>{lang ? "Contact us" : "تواصل معنا"}</a>
                <a onClick={changeLang} className="font-['Titillium Web'] font-normal text-[16px] leading-[26px] tracking-[-0.02em] text-[#222529] hover:text-[#868E96]" href='#'>{flag ? "| Arabic" : "| English"}</a>
            </div>
            <div onClick={closeMenu} className={`w-[100vw] h-[100vh] bg-[#00000035] fixed top-0 left-0 z-10 ${props.visible ? "block" : "hidden"}`}></div>
        </div>
    )
}

export default SideMenu;