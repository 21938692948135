import React from 'react';
import {useNavigate} from 'react-router-dom';


function About() {
    const navigate = useNavigate();
    return (
        <>
            <div className='about-header'>
                <img className='w-[139px] h-[51px]' src='./assets/logo.svg' alt='logo'/>
                <button onClick={() => navigate('/about/deleteme')}> Delete My Store </button>        
            </div>
            <div className="p-[80px] max-[800px]:p-[30px]">
                <div className="flex flex-col w-full mb-[150px] max-[800px]:mb-[80px]">
                    <p className="font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] max-[800px]:text-[32px] max-[800px]:leading-[40px] tracking-[-0.02em] text-[#1F1E2D] pb-[70px] max-[800px]:pb-[48px]">
                        Whether you own a store or are looking for one, Linko is the only app you need.
                    </p>
                    <p className="mb-[30px] font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] max-[800px]:text-[16px] max-[800px]:leading-[20px] text-justify text-[#626167]">
                        Find any product or business with a few taps on one app. Looking for a couch, a mobile charger and clothes
                        all while you have your morning coffee? Do it all via Linko. Add stores to your favorites list, be the
                        first to know about their offers and discounts, and make shopping what it should be - FUN.
                    </p>
                    <p className="mb-[30px] font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] max-[800px]:text-[16px] max-[800px]:leading-[20px] text-justify text-[#626167]">
                        What's in it for the business owners? Well, your business can only grow when your customer is in the know.
                        Linko has one mission only - to help people find you. List your store details and products on the app so
                        everyone can find you instantly. Prompt visitors to add your store to their Linko list. Run offers and
                        promotions to keep your store on top.
                    </p>
                    <p className="mb-[50px] font-['Helvetica Neue sans-serif'] text-[21px] leading-[25px] max-[800px]:text-[16px] max-[800px]:leading-[20px] text-justify text-[#626167]">
                        Many businesses, one platform - Linko is here to make finding stores as easy as 1,2,3.
                    </p>
                    <div className='contact-us'>
                        <span>Contact us:</span>
                        <a href='mailto: Hello@heylinko.com'>Hello@heylinko.com</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;