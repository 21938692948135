import React, { useContext, useEffect, useState } from 'react';
import {Zoom, Bounce} from 'react-reveal';
import { UserContext } from '../../App';

function How() {

    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    return (
        <div id="How" className="flex flex-col itmes-center max-[1200px]:px-[20px]">
            <Zoom>
            <div>
                <p className="font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] tracking-[-0.02em] text-[#1F1E2D] text-center pb-[59px] max-[480px]:text-[40px] max-[450px]:text-[30px] max-[550px]:pb-[35px]">{lang? "Download the App!":"حمل التطبيق!"}</p>
                <div className="flex flex-row justify-center">
                    <button className="w-[270px] h-[89px] max-[550px]:w-[150px] max-[550px]:h-[50px] rounded-[20px] border-2 border-solid border-[#1F1F39] mr-6">
                        <div className="flex flex-row justify-center items-center">
                            <img className='w-[51px] h-[51px] pr-[15px] max-[550px]:w-[28px] max-[550px]:h-[28px] max-[550px]:pr-[5px] box-content' src="./assets/appstore.png" alt="App Store" />
                            <p className="font-['Roboto sans-serif'] font-[700] text-[20px] max-[550px]:text-[15px] leading-[28px] text-center text-[#1F1F39]">App Store</p>
                        </div>
                    </button>
                    <button className="w-[270px] h-[89px] max-[550px]:w-[150px] max-[550px]:h-[50px] rounded-[20px] border-2 border-solid border-[#1F1F39]">
                        <div className="flex flex-row justify-center items-center">
                            <img className='w-[51px] h-[51px] max-[550px]:w-[28px] max-[550px]:h-[28px] max-[550px]:pr-[5px] pr-[15px] box-content' src="./assets/playstore.png" alt="Play Store" />
                            <p className="font-['Roboto sans-serif'] font-[700] text-[20px] max-[550px]:text-[15px] leading-[28px] text-center text-[#1F1F39]">Play Store</p>
                        </div>
                    </button>
                </div>
            </div>
            </Zoom>
            <div className='flex flex-col mt-[171px] mb-[94px] max-[550px]:mt-[70px]'>
                <Zoom>
                <p className="font-['Sen sans-serif'] font-[700] text-[40px] leading-[48px] tracking-[-0.02em] text-[#1F1E2D] text-center mb-[11px]">{lang? "How it works?":"كيف يعمل الموقع؟"}</p>
                <p className="font-['Open Sans'] text-[16px] leading-[29px] text-[#969696] text-center max-[550px]:text-justify tracking-[0.05em]">{lang ? "While we can customize your plan to suit your needs,<br/> most clients schedule regular services:":"بينما يمكننا تخصيص خطتك لتناسب احتياجاتك ، يقوم معظم العملاء بجدولة خدمات منتظمة:"}</p>
                </Zoom>
                <div className='flex flex-row max-[800px]:flex-col items-center justify-between pt-[45px]'>
                    <Bounce left>
                    <div className='flex flex-col w-[360px] h-[327px] max-[420px]:w-[328PX] max-[420px]:h-[298px] m-5 p-8 rounded-[14px] border-[1px] border-solid border-[#F2F2F2] max-[940px]:p-4'>
                        <img className="w-[64px] h-[64px]" src='./assets/how-icon.png' alt='home' />
                        <p className="font-['Open Sans'] font-[600] text-[20px] leading-[27px] capitalize text-[#323232] pt-6">{lang?"Look Up The Store":"ابحث عن المتجر"}</p>
                        <p className="font-['Open Sans'] text-[16px] leading-[29px] text-[#969696] pt-[16px]">{lang? "Create, edit, copy, move, download your files easily, everywhere, every time. Use it as your personal cloud.":"قم بإنشاء ملفاتك وتعديلها ونسخها ونقلها وتنزيلها بسهولة في كل مكان وفي كل مرة. استخدمه كسحابة شخصية."}</p>
                    </div>
                    </Bounce>

                    <Bounce top>
                    <div className='flex flex-col w-[360px] h-[327px] max-[420px]:w-[328PX] max-[420px]:h-[298px] max-[940px]:p-4 m-5 p-8 rounded-[14px] border-[1px] border-solid border-[#F2F2F2]'>
                        <img className="w-[64px] h-[64px]" src='./assets/how-icon.png' alt='home' />
                        <p className="font-['Open Sans'] font-[600] text-[20px] leading-[27px] capitalize text-[#323232] pt-6">{lang? "Open The Store Website":"افتح موقع المتجر"}</p>
                        <p className="font-['Open Sans'] text-[16px] leading-[29px] text-[#969696] pt-[16px]">{lang? "Ajax Upload, Ability to drag & drop, multiple files upload and upload using url with file extensions filter":"أجاكس ابلود ، القدرة على السحب والإسقاط ، تحميل وتحميل ملفات متعددة باستخدام url مع مرشح ملحقات الملفات"}</p>
                    </div>
                    </Bounce>
                    <Bounce right>
                    <div className='flex flex-col w-[360px] h-[327px] max-[420px]:w-[328px] max-[420px]:h-[298px] max-[940px]:p-4 m-5 p-8 rounded-[14px] border-[1px] border-solid border-[#F2F2F2]'>
                        <img className="w-[64px] h-[64px]" src='./assets/how-icon.png' alt='home' />
                        <p className="font-['Open Sans'] font-[600] text-[20px] leading-[27px] capitalize text-[#323232] pt-6">{lang?"Place Your Order!":"ضع طلبك!"}</p>
                        <p className="font-['Open Sans'] text-[16px] leading-[29px] text-[#969696] pt-[16px]">{lang?"Multiple users are supported, each one with their own folder, permissions and access roles.":"يتم دعم العديد من المستخدمين ، ولكل منهم مجلده الخاص والأذونات وأدوار الوصول."}</p>
                    </div>
                    </Bounce>
                </div>
            </div>
            <hr className='w-[80%] ml-[10%]'/>
        </div>
    )
}

export default How;