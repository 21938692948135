import React, { useContext, useState, useEffect } from 'react';
import {LightSpeed} from 'react-reveal';
import { UserContext } from '../../App';

function Feature() {
    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])
    return (
        <div id="Feature" className='flex flex-col pt-20 h-full'>
            <LightSpeed left>
            <div className='flex flex-row max-[900px]:flex-col max-[900px]:items-center justify-around pb-[143px]'>
                <img className="w-[332px] h-[664px] max-[550px]:w-[220px] max-[550px]:h-[440px]" src="./assets/feature-phone1.png" alt="Feature Phone" />
                <div className="flex flex-col justify-center w-[470px] max-[470px]:w-full max-[900px]:mt-10">
                    <p className="font-['Sen sans-serif'] font-[700] text-[60px] max-[550px]:text-[40px] leading-[72px] max-[550px]:leading-[48px] track-[-0.02em] text-[#1F1E2D] pb-10 max-[550px]:px-5 max-[550px]:text-center">{lang? "Double Click to Save Any Store!":"انقر نقرًا مزدوجًا لحفظ أي متجر!"}</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] max-[550px]:px-5 leading-[35px] text-[#626167] max-[550px]:text-center">{lang? "Download Linko mobile app for iOS and Android. it helps you save your favorite stores quickly and more smartly.":"قم بتنزيل تطبيق Linko للجوال لنظامي iOS و Android. يساعدك على حفظ متاجرك المفضلة بسرعة وذكاء أكبر."}</p>
                </div>
            </div>
            </LightSpeed>
            <LightSpeed right>
            <div className='flex flex-row max-[900px]:flex-col max-[900px]:justify-around max-[900px]:items-center justify-around pb-[177px]'>
                <div className="flex flex-col justify-center w-[470px] max-[470px]:w-full max-[900px]:mb-10">
                    <p className="font-['Sen sans-serif'] font-[700] text-[60px] leading-[72px] max-[550px]:text-[40px] max-[550px]:leading-[48px] max-[550px]:px-5 track-[-0.02em] text-[#1F1E2D] pb-10 max-[550px]:text-center">{lang? "Search for the store or items":"ابحث عن المتجر أو العناصر"}</p>
                    <p className="font-['Helvetica Neue sans-serif'] text-[21px] leading-[35px] text-[#626167] max-[550px]:px-5 max-[550px]:text-center">{lang? "Download Linko mobile app for iOS and Android. it helps you save your favorite stores quickly and more smartly.":"باستخدام البحث الذكي والسريع ، يمكنك البحث عما تريد من المتاجر أو العناصر في ثانية!"}</p>
                </div>
                <div className='relative'>
                    <div className="flex flex-row justify-center items-center -translate-x-[31px] z-20 absolute bottom-24 w-[103px] h-[103px] rounded-[50%] bg-[#F8F8FA] shadow-[0_2px_5px_rgba(0,0,0,0.15)] max-[550px]:w-[70px] max-[550px]:h-[70px] max-[550px]:bottom-16">
                        <img className="max-[550px]:w-[35px] max-[550px]:h-[35px]" width={50} height={55} src='./assets/Filter.png' alt="percent"/>
                    </div>
                    <img className="w-[332px] h-[664px] max-[550px]:w-[220px] max-[550px]:h-[440px]" src="./assets/feature-phone2.png" alt="Feature Phone" />
                </div>
            </div>
            </LightSpeed>
            <hr className='w-[80%] ml-[10%]'/>
        </div>
    )
}

export default Feature;