import React from 'react';
import Home from '../Home';
import How from '../How';
import Feature from '../Feature';
import Faq from '../Faq';
import Header from '../Header';
import ScrollToTop from "react-scroll-to-top";

function Landing({flag, change}) {
    return (
        <div>
            <ScrollToTop smooth color="#EA3557"/>
            <Header flag={flag} change={change}/>
            <Home />
            <How />
            <Feature />
            <Faq />
        </div>
    )
}

export default Landing;