import React from 'react';
import {useRef, useEffect, useContext, useState} from 'react';
import { UserContext } from '../../App';

function Collapse() {
    
    const addIcon = useRef();
    const closeIcon = useRef();
    const faqContent = useRef();
    const [hidden, setHidden] = useState(true);
    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    function showContent() {
        const flag = !hidden;
        setHidden(flag);
        console.log(flag);
        const showStyle = flag ? "none" : "block";
        faqContent.current.style.display = showStyle;
        addIcon.current.style.display = flag? "block" : "none";
        closeIcon.current.style.display = flag? "none" : "block";
    }

    return (
        <div className="flex flex-col mb-10 w-[80%] max-[550px]:w-[300px] max-[550px]:box-content">
                <div className="bg-[#F8F8FA] rounded-[10px] flex flex-col">
                    <div onClick={showContent} className='flex flex-row justify-between px-[29px] py-[21px] max-[550px]:p-[15px]'>
                        <p className="font-['Roboto sans-serif'] font-[600] text-[16px] leading-[24px] max-[550px]:text-[14px] text-[#1F1E2D]">{lang? "What often will results be reported?":"ما هي النتائج في كثير من الأحيان؟"}</p>
                        <img ref={addIcon} width={24} height={24} src='./assets/expand.png' alt='expand' />
                        <img ref={closeIcon} className="hidden" width={24} height={24} src='./assets/close.png' alt='close' />
                    </div>
                    <p ref={faqContent} className="font-['Roboto sans-serif'] text-[16px] leading-[24px] text-[#A2A9B0] text-justify px-[29px] pb-[40px] max-[430px]:px-[15px] max-[430px]:py-[10px] hidden">{lang? "Because TanahAir provides the best service to customers and provides flexibility to solve problems with our experts so that customers get satisfaction. And we provide service very quickly according to the price we offer":"لأن شركة تناهير تقدم أفضل خدمة للعملاء وتوفر المرونة في حل المشكلات مع خبرائنا حتى ينال العملاء رضاهم. ونقدم الخدمة بسرعة كبيرة حسب السعر الذي نقدمه"}</p>
                </div>
        </div>
    )
}

export default Collapse;