import React, { useContext, useEffect, useState } from 'react';
import Collapse from '../Collapse';
import {Zoom, Slide} from 'react-reveal';
import { UserContext } from '../../App';

function Faq() {

    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    return (
        <div id="Faq" className="flex flex-col justify-between items-center pt-[145px] pb-[171px] h-full">
            <Zoom>
            <p className="font-['Raleway sans-serif'] font-[500] text-[48px] max-[900px]:text-[36px] leading-[64px] text-[#1F1E2D] pb-12 max-[550px]:text-[25px]">{lang? "Frequently Asked Question":"أسئلة شائعة"}</p>
            </Zoom>
            <Collapse />
            <Collapse />
            <Collapse />
            <Collapse />
            <Collapse />
            <Collapse />
            <Collapse />
            <Collapse />
        </div>
    );
}

export default Faq;