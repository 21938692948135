import React, {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../App';

function Footer() {
    
    const flag = useContext(UserContext);
    const [lang, setLang] = useState();

    useEffect(() => {
        setLang(flag);
    }, [flag])

    return (
        <div id="Footer" className='flex flex-row max-[950px]:flex-col max-[950px]:items-center max-[950px]:h-full justify-between w-full bg-[#F8F8FA] mb-[80px] max-[550px]:mb-[30px] px-[77px] py-[52px]'>
            <div className="flex flex-col justify-between w-[360px] max-[400px]:w-[330px]">
                <div className="flex flex-col">
                    <img className="w-[139px] h-[51px]" src='./assets/logo.svg' alt="Linko"/>
                    <p className="font-['Cuprum'] italic text-[16px] leading-[28px] text-[#0A0A0A]">{lang?"Linko! all stores in one App" : "لينكو! جميع المتاجر في تطبيق واحد" }</p>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row justify-start mt-4 mb-4">
                        <a href="https://instagram.com/heylinko" target="_blank"><img className="w-[32px] h-[32px] mr-6" src='./assets/Instagram.png' alt="Instagram"/></a>
                        <a href="http://twitter.com/heylinko" target="_blank"><img className="w-[32px] h-[32px] mr-6" src='./assets/Twitter.png' alt="Twitter"/></a>
                        <a href="https://youtube.com/heylinko" target="_blank"><img className="w-[32px] h-[32px]" src='./assets/Youtube.png' alt="Youtube"/></a>
                    </div>
                    <p className="font-['Cuprum'] italic text-[13px] leading-[28px] text-black">Linko 2023</p>
                </div>
            </div>
            <div>
                <div>
                    <p className="font-['Open Sans'] font-[700] mb-3 text-[16px] leading-[16px] text-[#323232]">{lang? "Our Office":"عنوانا"}</p>
                    <p id="address1" className="font-['Open Sans'] font-[600] text-[14px] leading-[19px] text-[#969696]">
                        Kuwait city, Jibla, Block 11, Street 55, Building number 7, Floor14 Kuwait City
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;



